import React from "react";

function Blamck() {
  return (
    <div className="description-container">
      <div className="description">
        <p>sads</p>

      </div>
    </div>
  );
}

export default Blamck;
